import { ChangeEvent, useState } from "react";
import { useQueryClient } from "react-query";

import ADMIN_COMMON_QUERY, {
  ADMIN_COMMON_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/ADMIN_COMMON_QUERY";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";

export default function useShipmentCommentList({
  shipmentId,
}: {
  shipmentId: number;
}) {
  const queryClient = useQueryClient();

  const { data: shipmentCommentList } =
    ADMIN_COMMON_QUERY.useGetShipmentCommentList({
      shipmentId,
    });

  const [comment, setComment] = useState("");

  const { mutate: updateComment } =
    ADMIN_COMMON_QUERY.useUpdateShipmentComment();

  const { mutate: editComment } = ADMIN_COMMON_QUERY.useEditShipmentComment();

  const { mutate: deleteComment } =
    ADMIN_COMMON_QUERY.useDeleteShipmentComment();

  const { handleSnackbarOpen } = useSnackbar();

  const handleCommentInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setComment(e.target.value);
  };

  const handleCommentUpdate = () => {
    updateComment(
      {
        pathParams: {
          shipmentId,
        },
        message: comment,
      },
      {
        onSuccess: () => {
          setComment("");
          handleSnackbarOpen("댓글을 등록했습니다.");

          queryClient.invalidateQueries(
            ADMIN_COMMON_QUERY_KEY_GEN.getShipmentCommentList({
              shipmentId,
            })
          );
          return;
        },

        onError: () => {
          handleSnackbarOpen("요청에 실패했습니다.", "error");
        },
      }
    );
  };

  const handleCommentEdit = ({
    message,
    cancelEditMode,
    commentId,
  }: {
    message: string;
    cancelEditMode: () => void;
    commentId: number;
  }) => {
    editComment(
      {
        pathParams: {
          commentId,
        },
        message,
      },
      {
        onSuccess: () => {
          cancelEditMode();
          handleSnackbarOpen("댓글을 수정했습니다.");

          queryClient.invalidateQueries(
            ADMIN_COMMON_QUERY_KEY_GEN.getShipmentCommentList({
              shipmentId,
            })
          );
          return;
        },

        onError: ({ response }) => {
          if (response?.data?.code === 403) {
            handleSnackbarOpen("수정할 권한이 없습니다.", "error");
            return;
          }
          handleSnackbarOpen("요청에 실패했습니다.", "error");
        },
      }
    );
  };

  const handleCommentDelete = (commentId: number) => {
    deleteComment(
      {
        pathParams: {
          commentId,
        },
      },
      {
        onSuccess: () => {
          handleSnackbarOpen("댓글을 삭제했습니다.");

          queryClient.invalidateQueries(
            ADMIN_COMMON_QUERY_KEY_GEN.getShipmentCommentList({
              shipmentId,
            })
          );
          return;
        },

        onError: ({ response }) => {
          if (response?.data?.code === 403) {
            handleSnackbarOpen("삭제할 권한이 없습니다.", "error");
            return;
          }
          handleSnackbarOpen("요청에 실패했습니다.", "error");
        },
      }
    );
  };

  return {
    handleCommentInputChange,
    handleCommentUpdate,
    comment,
    shipmentCommentList,
    handleCommentEdit,
    handleCommentDelete,
  };
}
