import { AdminCouponListItem } from "@sellernote/_shared/src/types/forwarding/coupon";

import { TableHeadCell } from "../../../../Table";

const HEAD_CELL_LIST: TableHeadCell<keyof AdminCouponListItem>[] = [
  {
    id: "campaignName",
    disablePadding: false,
    width: 150,
    label: "쿠폰명",
  },
  {
    id: "kind",
    disablePadding: false,
    label: "쿠폰형식",
  },
  {
    id: "code",
    disablePadding: false,
    label: "코드",
  },
  {
    id: "discountAmount",
    disablePadding: false,
    label: "차감액",
  },
  {
    id: "startDate",
    disablePadding: false,
    label: "시작/종료일",
  },
  {
    id: "createdAt",
    disablePadding: false,
    label: "등록일",
  },
];

export default HEAD_CELL_LIST;
