import { useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, List, ListItem, ListItemText } from "@mui/material";

import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";

import TextField from "../TextField";
import { TransformedComment } from ".";

function ManualShipmentCommentList({
  transformedCommentList,
  onCommentEdit,
  onCommentDelete,
}: {
  transformedCommentList: TransformedComment[];
  onCommentEdit: ({
    message,
    cancelEditMode,
    commentId,
  }: {
    message: string;
    cancelEditMode: () => void;
    commentId: number;
  }) => void;
  onCommentDelete: (commentId: number) => void;
}) {
  const [commentId, setCommentId] = useState<number | null>(null);
  const [editInputValue, setEditInputValue] = useState("");

  return (
    <List>
      {transformedCommentList.map((comment, index) => {
        const isEditable = commentId && commentId === comment.commentId;

        return (
          <ListItem
            key={index}
            sx={{ display: "flex", alignItems: "flex-start", gap: 2 }}
            disablePadding
          >
            <ListItemText
              sx={{ flex: "0 0 auto" }}
              secondary={toFormattedDate(
                comment.date,
                "YYYY.MM.DD a hh:mm:ss",
                false
              )}
            >
              {comment.id}
            </ListItemText>

            {isEditable ? (
              <TextField
                value={editInputValue}
                fullWidth={true}
                size="small"
                onChange={(e) => setEditInputValue(e.target.value)}
              />
            ) : (
              <ListItemText sx={{ flex: 1 }} primary={comment.comment} />
            )}

            {!isEditable && (
              <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => {
                    setCommentId(comment.commentId);
                    setEditInputValue(comment.comment);
                  }}
                >
                  <EditIcon fontSize="inherit" />
                </IconButton>

                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => onCommentDelete(comment.commentId)}
                >
                  <DeleteIcon color="error" fontSize="inherit" />
                </IconButton>
              </Box>
            )}

            {isEditable && (
              <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => {
                    onCommentEdit({
                      message: editInputValue,
                      cancelEditMode: () => {
                        setCommentId(null);
                        setEditInputValue("");
                      },
                      /** 수정 기능은 commentPageType === "ManualShipment"일때만 가능하기 때문에 타입이 보장됨 */
                      commentId: comment.commentId,
                    });
                  }}
                >
                  <CheckIcon fontSize="inherit" color="success" />
                </IconButton>

                <IconButton aria-label="delete" size="small">
                  <CloseIcon
                    color="error"
                    fontSize="inherit"
                    onClick={() => {
                      setEditInputValue("");
                      setCommentId(null);
                    }}
                  />
                </IconButton>
              </Box>
            )}
          </ListItem>
        );
      })}
    </List>
  );
}

export default ManualShipmentCommentList;
