import { useCallback, useState } from "react";

import { DetailType } from "@sellernote/_shared/src/types/forwarding/adminBidUser";

import useSnackbar from "../../../../hooks/useSnackbar";

export default function useHandleCoupon({ type }: { type: DetailType }) {
  const { handleSnackbarOpen } = useSnackbar();

  const [opensIssueCouponModal, setOpensIssueCouponModal] = useState(false);

  const [opensTeamUserListModal, setOpensTeamUserListModal] = useState(false);

  const [selectedUserIdForTeam, setSelectedUserIdForTeam] = useState<
    undefined | number
  >(undefined);

  const handleCouponButtonClick = useCallback(() => {
    type === "user"
      ? setOpensIssueCouponModal(true)
      : setOpensTeamUserListModal(true);
  }, [type]);

  const handleSelectedUserIdGet = (userId: number) => {
    setSelectedUserIdForTeam(userId);
  };

  const handleSelectButtonClick = () => {
    if (!selectedUserIdForTeam) {
      handleSnackbarOpen("유저를 선택해주세요.", "warning");
      return;
    }

    handleSnackbarOpen("유저가 선택되었습니다.");
    setOpensTeamUserListModal(false);
    setOpensIssueCouponModal(true);
  };

  const handleModalClose = () => {
    setOpensTeamUserListModal(false);
  };

  const resetSelectedUserId = () => {
    setSelectedUserIdForTeam(undefined);
  };

  return {
    opensIssueCouponModal,
    setOpensIssueCouponModal,

    opensTeamUserListModal,

    selectedUserIdForTeam,

    handleCouponButtonClick,
    handleSelectedUserIdGet,
    handleSelectButtonClick,
    handleModalClose,

    resetSelectedUserId,
  };
}
