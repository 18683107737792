import { List, ListItem, ListItemText } from "@mui/material";

import { Comment } from "@sellernote/_shared/src/types/forwarding/trello";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";

function CommonCommentList({
  transformedCommentList,
}: {
  transformedCommentList: Comment[];
}) {
  return (
    <List>
      {transformedCommentList.map((comment, index) => {
        /** 의뢰의 자동 댓글인 경우 서버에서 이미 변환된 날짜 정보를 주고 있어 Date인지 문자열인지 확인을 한다 */
        const commentDate = (() => {
          if (isNaN(Date.parse(comment.date))) return comment.date;

          return toFormattedDate(comment.date, "YYYY.MM.DD a hh:mm:ss", false);
        })();

        return (
          <ListItem
            key={index}
            sx={{ display: "flex", alignItems: "flex-start", gap: 2 }}
            disablePadding
          >
            <ListItemText sx={{ flex: "0 0 auto" }} secondary={commentDate}>
              {comment.id}
            </ListItemText>

            <ListItemText sx={{ flex: 1 }} primary={comment.comment} />
          </ListItem>
        );
      })}
    </List>
  );
}

export default CommonCommentList;
